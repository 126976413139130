export const amplitudeEventNames = {
  BTN_SESSION_START: 'btn_session_start',
  BTN_START_STRATEGY: 'btn_start_strategy',
  BTN_START_BACKTEST: 'btn_start_backtest',
  BTN_START_TRADING: 'btn_start_trading',
  BTN_START_SUBSCRIBE: 'btn_start_subscribe',
  BTN_APP_DOWNLOAD: 'btn_app_download',
  BTN_UPDATE_SUBSCRIBE: 'btn_update_subscribe',
  H_TB_LOGO: 'h_tb_logo',
  H_MENU_STRTG: 'h_menu_strtg',
  H_MENU_MARKET: 'h_menu_market',
  H_MENU_BT: 'h_menu_bt',
  H_MENU_BT_RESULT: 'h_menu_bt_result',
  H_MENU_BT_MARKETPLACE: 'h_menu_bt_marketplace',
  H_MENU_BT_BLOG: 'h_menu_bt_blog',
  H_MENU_CS: 'h_menu_cs',
  H_MENU_COMMUNITY: 'h_menu_community',
  H_MENU_MYPAGE: 'h_menu_mypage',
  H_MENU_ASSET: 'h_menu_asset',
  H_MENU_ROBOT: 'h_menu_robot',
  BTN_ACCOUNT_SIGNOUT: 'btn_account_signout',
  OC_GENERAL_PANEL: 'oc_general_panel',
  OC_SELECTPAIR_PANEL: 'oc_selectpair_panel',
  OC_FORMULA_PANEL: 'oc_formula_panel',
  OC_BUY_STRATEGY_PANEL: 'oc_buy_strategy_panel',
  OC_SELL_STRATEGY_PANEL: 'oc_sell_strategy_panel',
  BTN_RIGHT_SIDEBAR_FOLD: 'btn_right_sidebar_fold',
  BTN_BT_RESET: 'btn_bt_reset',
  INPUT_BT_START_DATE: 'input_bt_start_date',
  INPUT_BT_END_DATE: 'input_bt_end_date',
  BTN_SELECT_PAIR_MODAL_OPEN: 'btn_select_pair_modal_open',
  BTN_FORMULA_SELECT: 'btn_formula_select',
  BTN_FORMULA_DELETE: 'btn_formula_delete',
  BTN_FORMULA_ADD: 'btn_formula_add',
  BTN_FORMULA_SAVE: 'btn_formula_save',
  BTN_BUY_RESET: 'btn_buy_reset',
  BTN_BUY_SAVE: 'btn_buy_save',
  BTN_BUY_PREVIEW: 'btn_buy_preview',
  BTN_SELL_PREVIEW: 'btn_sell_preview',
  BTN_SELL_SAVE: 'btn_sell_save',
  BTN_BT_CANCEL: 'btn_bt_cancel',
  BTN_BT_START: 'btn_bt_start',
  TAB_SUMMARY: 'tab_summary',
  TAB_ANALYSIS: 'tab_analysis',
  TAB_HISTORY: 'tab_history',
  TAB_ROBOT_RESULT: 'tab_robot_result',
  TAB_ROBOT_MARKETS: 'tab_robot_markets',
  TAB_ROBOT_TRANSACTIONS: 'tab_robot_transactions',
  TAB_ROBOOT_HISTORY: 'tab_roboot_history',
  MARKET_ANALYSIS_TABLE_SORT: 'market_analysis_table_sort',
  BTN_HISTORY_PER_PAIR: 'btn_history_per_pair',
  BTN_BACK_TO_ANALYSIS: 'btn_bact_to_analysis',
  HISTORY_TABLE_FILTER: 'history_table_filter',
  FORMULA_GUIDE: 'formula_guide',
  HOME_BTN_START: 'home_btn_start',
  SAMPLE_STRATEGY_START: 'sample_strategy_start',
  BTN_STRATEGY_CALL: 'btn_strategy_call',
  BTN_MODIFY: 'btn_modify',
  BTN_BACKTESTING_FAIL: 'btn_backtesting_fail',
  BTN_APPLY_CHART: 'btn_apply_chart',
  BTN_TEMP_SAVE: 'btn_temp_save',
  BTN_BUY_PREVIEW_CANCEL: 'btn_buy_preview_cancel',
  BTN_SELL_PREVIEW_CANCEL: 'btn_sell_preview_cancel',
  BTN_ANALYSIS_CSV: 'btn_analysis_csv',
  BTN_HISTORY_CSV: 'btn_history_csv',
  BTN_SELECT_PAIR_MODAL_REFRESH: 'btn_select_pair_modal_refresh',
  BTN_ACCOUNT_START_DELETE: 'btn_account_start_delete',
  BTN_ACCOUNT_DELETE_CANCEL: 'btn_account_delete_cancel',
  BTN_ACCOUNT_DELETE: 'btn_account_delete',
  BTN_MARKETPLACE_ADD_START: 'btn_marketplace_add_start',
  BTN_MARKETPLACE_SELECT: 'btn_marketplace_select',
  BTN_MARKETPLACE_BACK: 'btn_marketplace_back',
  BTN_MARKETPLACE_DELETE: 'btn_marketplace_delete',
  BTN_MARKETPLACE_DETAIL: 'btn_marketplace_detail',
  BTN_MARKETPLACE_SELECT_BT: 'btn_marketplace_select_bt',
  BTN_MARKETPLACE_ADD: 'btn_marketplace_add',
  STRG_SORT: 'strg_sort',
  STRG_TAB: 'strg_tab',
  STRG_DETAIL: 'strg_detail',
  STRG_DELETE: 'strg_delete',
  BTN_SELECT_PAIR_MODAL_CLOSE: 'btn_select_pair_modal_close',
  BTN_SELECT_PAIR_MODAL_ORDER: 'btn_select_pair_modal_order',
  STRG_EDIT: 'strg_edit',
  STRG_COPY: 'strg_copy',
  ASSET_TAB: 'asset_tab',
  BTN_EXCHANGE_CONNECT: 'btn_exchange_connect',
  BTN_COINONE_SIGNIN: 'btn_coinone_signin',
  BTN_COINONE_SIGNUP: 'btn_coinone_signup',
  BTN_ACCOUNT_SIGNIN: 'btn_account_signin',
  BTN_ACCOUNT_SIGNUP: 'btn_account_signup',
  BTN_ROBOT_GUIDE: 'btn_robot_guide',
  BTN_ROBOT_ADD_START: 'btn_robot_add_start',
  BTN_ROBOT_DETAIL: 'btn_robot_detail',
  BTN_ROBOT_SELECT_BT: 'btn_robot_select_bt',
  BTN_ROBOT_AGREE: 'btn_robot_agree',
  BTN_ROBOT_ADD: 'btn_robot_add',
  BTN_ROBOT_START: 'btn_robot_start',
  BTN_ROBOT_STOP: 'btn_robot_stop',
  BTN_ROBOT_DELETE: 'btn_robot_delete',
  BTN_MARKETPLACE_FOLLOW: 'btn_marketplace_follow',
  BTN_MARKETPLACE_UNFOLLOW: 'btn_marketplace_unfollow',
  BTN_RELOAD: 'btn_reload',
  TAB_PERIOD_ALL: 'tab_period_all',
  TAB_PERIOD_SHORT: 'tab_period_short',
  TAB_PERIOD_MEDIUM: 'tab_period_medium',
  TAB_PERIOD_LONG: 'tab_period_long',
  BTN_TOOLTIP: 'btn_tooltip',
  CLICK_FAQ: 'click_FAQ',
};

export const amplitudeEventProperties = {
  date: 'date',
  formula_select_index: 'formula_select_index',
  formula_select_position: 'formula_select_position',
  sorting_value: 'sorting_value',
  index: 'index',
  analysis_csv_position: 'analysis_csv_position',
  history_csv_position: 'history_csv_position',
};
