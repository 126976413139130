import { useRouter } from 'next/router';

import { PropsWithChildren } from 'react';

import clsx from 'clsx';
import { routes } from 'constants/';
import { motion } from 'framer-motion';

import { useResponsive } from 'hooks';

import MobileNavigation from 'components/Navigation/MobileNavigation';

import styles from './index.module.scss';

const navigatationExcludedRoutes = [
  routes.backtestingRoute,
  routes.rootRoute,
  '/user/[username]/strategy/[strategyId]',
  '/user/[username]/tradingrobot/new',
  '/user/[username]/tradingrobot/[robotId]',
  '/marketplace/[strategyId]',
];

const MobileAppLayout = ({ children }: PropsWithChildren) => {
  const { isDesktop } = useResponsive();
  const router = useRouter();

  if (isDesktop) {
    return <>{children}</>;
  }

  return (
    <>
      {/* <AnimatePresence mode="sync"> */}
      <motion.div
        key={router.pathname}
        className={clsx(
          styles.root,
          navigatationExcludedRoutes.includes(router.pathname) &&
            styles.withoutNavigation,
        )}
        initial={{ opacity: 0, y: 50 }} // Slide in from bottom
        animate={{ opacity: 1, y: 0 }} // Slide to position
        // exit={{ opacity: 0, y: -50 }} // Slide out to top
        transition={{
          type: 'spring',
          stiffness: 120,
          damping: 20,
          duration: 0.5,
        }}
      >
        {children}
      </motion.div>
      {/* </AnimatePresence> */}

      {!isDesktop && !navigatationExcludedRoutes.includes(router.pathname) && (
        <MobileNavigation />
      )}
    </>
  );
};

export default MobileAppLayout;
